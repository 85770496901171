import { useQuery } from '@tanstack/vue-query'
import type { State, StateValue } from '@autobid/nuxt-pinia-store/types/Search'
import { storeToRefs } from 'pinia'
import { useSearchStore } from '@autobid/nuxt-pinia-store/store/searchStore/useSearchStore'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { getError } from '@autobid/strapi-integration/utils/getError'
import type { SearchCountResponse } from '../types/components/SearchResponse'
import type { APIFormatKey } from '../constants/SEARCH_API_FORMAT_TYPES'
import { SEARCH_API_FORMAT_TYPES } from '../constants/SEARCH_API_FORMAT_TYPES'
import { debounceAsync } from '../utils/debounceAsync'
import { FIVE_MINUTES_MS } from '../constants/FIVE_MINUTES_MS'
import { OPEN_APPS_ID } from '../constants/OPEN_APPS_ID'
import { useUserApps } from './useUserApps'

export const convertValueToApiFormat = (key: string, value: StateValue) => {
  if (!value) {
    return
  }

  const type = SEARCH_API_FORMAT_TYPES[key as APIFormatKey]

  // todo: replace with dedicated documentation
  if (!type) {
    // eslint-disable-next-line no-console
    // console.error(
    //   `
    //    Unrecognized parameter "${key}"!
    //    Search query doesn't accept "${key}" parameter.
    //    Please check your JSON input!
    //    You can check the accepted parameters in the following link:
    //    https://gitlab-p2.autobid.de/services/auctions/blob/develop/docs/GRAPHQL.md
    //    `
    // )
    return
  }

  switch (type) {
    case 'List[int]': {
      if (Array.isArray(value)) {
        return value.map(Number)
      }
      return [Number(value)]
    }
    case 'List[str]': {
      if (Array.isArray(value)) {
        return value.map(String)
      }
      return [String(value)]
    }
    case 'bool': {
      return Boolean(value)
    }
    case 'int': {
      return Number(value)
    }
    case 'str': {
      return value.toString()
    }
  }
}

const mapValuesToApiFormat = (searchValues: State, justCount: boolean) => {
  const reduced: Record<string, unknown> = Object.entries(searchValues).reduce(
    (acc, [key, value]) => {
      key = key.split('-')[0]
      const converted = convertValueToApiFormat(key, value)

      if (
        converted === undefined ||
        (Array.isArray(converted) && !converted.length)
      ) {
        return acc
      }

      acc[key] = converted
      return acc
    },
    {}
  )

  if (justCount) {
    reduced.justCountIt = 1
  }

  return Object.entries(reduced)
    .filter((el) => el.length)
    .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
    .join(', ')
}

type HandleSearchCardParams = {
  searchValues: State
  userAppIds: number[]
  justCount?: boolean
}

export const useHandleSearchCard = () => {
  const { $customFetch } = useCustomFetch()

  const handleSearchCard = async ({
    searchValues,
    userAppIds,
    justCount
  }: HandleSearchCardParams) => {
    delete searchValues.auth

    const filterUserApps = () => {
      const appId = Array.isArray(searchValues.appId)
        ? (searchValues.appId as (string | number)[]).map(Number)
        : [Number(searchValues.appId)]
      const filtered = userAppIds.filter((id) => appId.includes(Number(id)))

      if (!filtered.length) {
        delete searchValues.appId
      }
    }

    if (searchValues.appId) {
      filterUserApps()
    }

    const appId = searchValues.appId
      ? searchValues.appId
      : userAppIds ?? OPEN_APPS_ID // default use all user apps or open apps for unauthenticated users
    const _searchValues = mapValuesToApiFormat(
      {
        ...searchValues,
        appId
      },
      justCount
    )

    const result = await $customFetch<SearchCountResponse>('/api/backend', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        queryApi: 'auctions',
        queryUrl: '/api/v1/search/items',
        query: `{
            carIds(params: {${_searchValues}})
          }`
      }
    })

    if (justCount) {
      return {
        count: Number(result?.data?.carIds || 0)
      }
    }

    return {
      carIds: result?.data?.carIds ?? []
    }
  }

  return { handleSearchCard }
}

export const useSearchCarQuery = () => {
  const { debounced } = storeToRefs(useSearchStore())
  const route = useRoute()
  const { handleSearchCard } = useHandleSearchCard()
  const { userAppIds } = useUserApps()
  const push = usePush()

  // in order to trigger query refetch when query changes (route.query doesn't trigger it)
  const query = computed(() => {
    return route.query
  })

  const debouncedHandleSearch = debounceAsync(handleSearchCard)

  return useQuery({
    queryKey: ['searchResult', query],
    queryFn: async () => {
      const params = {
        searchValues: route.query,
        userAppIds: userAppIds.value,
        justCount: true
      }
      if (debounced.value) {
        return await debouncedHandleSearch(params)
      }
      return await handleSearchCard(params)
    },
    enabled: process.client,
    refetchInterval: FIVE_MINUTES_MS,
    staleTime: FIVE_MINUTES_MS,
    cacheTime: FIVE_MINUTES_MS,
    onError(e) {
      push.error(getError(e).message)
    }
  })
}
