export function debounceAsync<
  FunctionType extends (
    ...args: Parameters<FunctionType>
  ) => ReturnType<FunctionType>
>(func: FunctionType, delay = 500) {
  let timerId
  return (...args: Parameters<FunctionType>) => {
    clearTimeout(timerId)
    return new Promise((resolve) => {
      timerId = setTimeout(async () => {
        const result = await func(...args)
        resolve(result)
      }, delay)
    })
  }
}
